import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";
import AdminLayout from "./layouts/AdminLayout";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import ShippingComps from "./pages/ShippingComps";
import AddShippingComps from "./pages/AddShippingComps";
import UpdateShippingComps from "./pages/UpdateShippingComps";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<Login />} />
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="shipping-comps" element={<ShippingComps />} />
          <Route path="add-shipping-comps" element={<AddShippingComps />} />
          <Route
            path="update-shipping-comp/:id"
            element={<UpdateShippingComps />}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
