import { useSelector } from "react-redux";
import { RootState } from "../store";

const Dashboard = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      <div className="p-6 bg-white rounded-xl h-full">
        <h1 className="text-2xl font-bold text-left">Dashboard</h1>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="bg-[#f2f2f2] flex flex-col justify-center items-center rounded-lg  w-full max-w-screen-md py-32 space-y-3">
            <h1 className="text-3xl font-bold text-[#828282]">Selamat Datang</h1>
            <p className="text-[#bdbdbd]">{user.name}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
