import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { fetchShippingComps } from "../redux/shippingCompsSlice";
import { RootState, AppDispatch } from "../store";

const ShippingComps = () => {
  const dispatch = useDispatch<AppDispatch>();

  const shippingComps = useSelector((state: RootState) => state.shippingComps.data);
  const loading = useSelector((state: RootState) => state.shippingComps.loading);
  const error = useSelector((state: RootState) => state.shippingComps.error);

  useEffect(() => {
    dispatch(fetchShippingComps());
  }, [dispatch]);

  return (
    <>
      <div className="p-6 bg-white rounded-xl h-full shadow-lg">
        <div className="flex gap-3 items-center">
          <h1 className="text-2xl font-bold text-left">Shipping Comps</h1>
          <Link
            to="/admin/add-shipping-comps"
            className="bg-[#2f80ed] rounded-full"
          >
            <FiPlus className="text-white m-1 text-xl font-bold" />
          </Link>
        </div>

        <div className="mt-5">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
              <thead className="bg-[#f2f2f2]">
                <tr>
                  <th className="px-6 py-3 font-bold text-black rounded-md">
                    Nama
                  </th>
                </tr>
              </thead>
              <tbody>
                {shippingComps.map((comp: any) => (
                  <tr
                    key={comp.id}
                    className="cursor-pointer hover:bg-gray-100"
                    onClick={() => window.location.href = `/admin/update-shipping-comp/${comp.id}`}
                  >
                    <td className="px-6 py-3 border-b border-[#f2f2f2]">
                      {comp.name}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default ShippingComps;
