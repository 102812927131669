import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const login = async (email: string, password: string) => {
  const response = await axios.post(`${API_URL}/authentication/login`, {
    email,
    password,
  });
  return response.data;
};

export const logout = async (token: string) => {
  try {
    const response = await axios.post(
      `${API_URL}/authentication/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Logout failed", error);
    throw new Error("Logout failed");
  }
};
