import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store/index";
import { setUser } from "../redux/userSlice";
import { login } from "../services/authService";
import Swal from "sweetalert2";

const Login: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loginProccess, setLoginProccess] = useState<boolean>(false);

  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    if (user.token) {
      navigate("/profile");
    }
  }, [user.token, navigate]);

  const onSubmit = async (data: any) => {
    setLoginProccess(true);
    try {
      const userData = await login(data.email, data.password);
      dispatch(setUser(userData));
      Swal.fire({
        title: "Login Successful!",
        text: "You are now logged in.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/admin");
      });
      setLoginProccess(false);
    } catch (error) {
      console.error("Login failed", error);
      Swal.fire({
        title: "Login Failed",
        text: "Please check your email and password and try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoginProccess(false);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h2 className="text-3xl mb-4 font-bold">Login</h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-[#f8f8f8] border p-6 rounded-lg shadow-md w-1/3"
      >
        <div className="mb-4">
          <label className="block mb-1 text-[#808080]">Email</label>
          <input
            type="email"
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            className="w-full p-2 border rounded-lg"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">
              Email is required and must be valid
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block mb-1 text-[#808080]">Password</label>
          <input
            type="password"
            {...register("password", { required: true })}
            className="w-full p-2 border rounded-lg"
          />
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">Password is required</p>
          )}
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            disabled={loginProccess}
            className="w-2/3 bg-blue-500 text-white py-1 rounded-full"
          >
            {loginProccess ? "Loading..." : "Login"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
