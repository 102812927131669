import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { clearUser } from "../redux/userSlice";
import { logout } from "../services/authService";
import { RootState } from "../store";
import Swal from "sweetalert2";

const Header: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const token = useSelector((state: RootState) => state.user.token);
  const [processLogout, setProcessLogout] = useState<boolean>(false);

  const isAdminRoute =
    pathname === "/admin" ||
    pathname === "/admin/shipping-comps" ||
    pathname === "/admin/add-shipping-comps" ||
    pathname === "/admin/update-shipping-comps";
  
  const isProfileRoute = pathname === "/profile";

  const handleLogout = async () => {
    setProcessLogout(true);
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      await logout(token);

      setProcessLogout(false);
      dispatch(clearUser());

      navigate("/");

      Swal.fire({
        title: "Logged Out",
        text: "You have been logged out successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      setProcessLogout(false);

      Swal.fire({
        title: "Logout Failed",
        text: "There was a problem logging you out. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  return (
    <header className="bg-[#4678f3] text-white px-5 flex items-center justify-between">
      <h1 className="text-xl font-bold">
        {isAdminRoute ? "KLEDO TEST ADMIN" : "KLEDO TEST"}
      </h1>
      <div className="flex items-center gap-5">
        {isAdminRoute ? (
          <>
            <button
              onClick={handleProfile}
              className={`flex items-center gap-2 py-2 font-bold text-white`}
            >
              {user.profileImage && (
                <img
                  src={user.profileImage}
                  alt="Profile"
                  className="w-8 h-8 rounded-full border-2 border-red-600"
                />
              )}
              {user.name || "Profile"}
            </button>
          </>
        ) : (
          <>
            {user.token ? (
              <>
                <button
                  onClick={handleProfile}
                  className={`px-3 py-2 ${isProfileRoute ? 'bg-black text-white' : ''}`}
                >
                  Profile
                </button>
                <button
                  onClick={handleLogout}
                  className={` px-3 py-2 ${isProfileRoute ? '' : 'bg-black'}`}
                >
                  {processLogout ? "Loading..." : "Logout"}
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleProfile}
                  className={`px-3 py-2 ${isProfileRoute ? 'bg-black' : ''}`}
                >
                  Profile
                </button>
                <button
                  onClick={() => navigate("/")}
                  className={`px-3 py-2 ${isProfileRoute ? '' : 'bg-black'}`}
                >
                  Login
                </button>
              </>
            )}
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
