import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import * as shippingCompsService from "../services/shippingCompsService";

const getAuthToken = (state: RootState) => state.user.token;

export const fetchShippingComps = createAsyncThunk(
  "shippingComps/fetchShippingComps",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = getAuthToken(state);
    try {
      return await shippingCompsService.fetchShippingComps(token);
    } catch (error) {
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const addShippingComp = createAsyncThunk(
  "shippingComps/addShippingComp",
  async (name: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = getAuthToken(state);
    try {
      return await shippingCompsService.addShippingComp(token, name);
    } catch (error) {
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const updateShippingComp = createAsyncThunk(
  "shippingComps/updateShippingComp",
  async (
    { id, name }: { id: string; name: string },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token = getAuthToken(state);
    try {
      return await shippingCompsService.updateShippingComp(token, id, name);
    } catch (error) {
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const deleteShippingComp = createAsyncThunk(
  "shippingComps/deleteShippingComp",
  async (id: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = getAuthToken(state);
    try {
      return await shippingCompsService.deleteShippingComp(token, id);
    } catch (error) {
      return rejectWithValue("An unknown error occurred");
    }
  }
);

interface ShippingCompsState {
  data: Array<{ id: string; name: string }>;
  loading: boolean;
  error: string | null;
}

const initialState: ShippingCompsState = {
  data: [],
  loading: false,
  error: null,
};

const shippingCompsSlice = createSlice({
  name: "shippingComps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShippingComps.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShippingComps.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchShippingComps.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(addShippingComp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addShippingComp.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(addShippingComp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(updateShippingComp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateShippingComp.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.data.findIndex(
          (comp) => comp.id === action.payload.id
        );
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(updateShippingComp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteShippingComp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteShippingComp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((comp) => comp.id !== action.meta.arg);
      })
      .addCase(deleteShippingComp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default shippingCompsSlice.reducer;
