import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Profile: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.token) {
      navigate("/");
    }
  }, [user.token, navigate]);

  if (!user.token) {
    return <h1 className="mx-5 my-5">to login page</h1>;
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h2 className="text-3xl mb-4 font-bold">Profile</h2>
      <div className="bg-[#f8f8f8] border p-6 rounded-lg shadow-md w-2/3">
        <div className="flex justify-between">
          <div className="space-y-10">
            <div>
              <h3 className="text-[#bcbcbc] text-xl">Nama</h3>
              <p className="font-bold text-md">{user.name}</p>
            </div>
            <div>
              <h3 className="text-[#bcbcbc] text-xl">No. HP</h3>
              <p className="font-bold text-md">{user.phoneNumber}</p>
            </div>
            <div>
              <h3 className="text-[#bcbcbc] text-xl">Email</h3>
              <p className="font-bold text-md">{user.email}</p>
            </div>
          </div>
          <div>
            <img
              src={user.profileImage}
              alt="Profile"
              className="rounded-full w-[150px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
