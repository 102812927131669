import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../redux/userSlice';
import shippingCompsReducer from '../redux/shippingCompsSlice';

const userPersistConfig = {
  key: 'user',
  storage,
};

const shippingCompsPersistConfig = {
  key: 'shippingComps',
  storage,
};

const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedShippingCompsReducer = persistReducer(shippingCompsPersistConfig, shippingCompsReducer);

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    shippingComps: persistedShippingCompsReducer,
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
