/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store";
import {
  fetchShippingComps,
  updateShippingComp,
  deleteShippingComp,
} from "../redux/shippingCompsSlice";
import { useForm, SubmitHandler } from "react-hook-form";
import { RiDeleteBin7Line } from "react-icons/ri";
import Swal from "sweetalert2";

interface ShippingCompData {
  name: string;
}

const UpdateShippingComps = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const shippingComps = useSelector(
    (state: RootState) => state.shippingComps.data
  );

  const [currentComp, setCurrentComp] = useState<{
    id: string;
    name: string;
  } | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ShippingCompData>();

  useEffect(() => {
    const fetchData = () => {
      const comp = shippingComps.find((comp) => comp.id == id);
      if (comp) {
        setCurrentComp(comp);
        setValue("name", comp.name);
      }
    };

    fetchData();
  }, [id, shippingComps, dispatch, setValue]);

  const onSubmit: SubmitHandler<ShippingCompData> = async (data) => {
    if (currentComp) {
      try {
        await dispatch(
          updateShippingComp({ id: currentComp.id, name: data.name })
        );
        Swal.fire({
          title: "Update Successful!",
          text: "Shipping company updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/admin/shipping-comps");
        });
      } catch (error) {
        Swal.fire({
          title: "Update Failed",
          text: "Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const handleDelete = async () => {
    if (currentComp) {
      try {
        await Swal.fire({
          title: "Are you sure?",
          text: "This action cannot be undone.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(deleteShippingComp(currentComp.id));
            Swal.fire({
              title: "Deleted!",
              text: "Shipping company has been deleted.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              navigate("/admin/shipping-comps");
            });
          }
        });
      } catch (error) {
        Swal.fire({
          title: "Delete Failed",
          text: "Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  return (
    <div className="p-6 bg-white rounded-xl h-full shadow-lg">
      <div className="flex gap-3 items-center">
        <h1 className="text-2xl font-bold text-left">Update Shipping Comp</h1>
        <button
          onClick={handleDelete}
          className="bg-[#eb5757] rounded-full"
        >
          <RiDeleteBin7Line className="text-white m-1 text-xl font-bold" />
        </button>
      </div>

      {currentComp ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block mb-1 text-[#808080]">Nama</label>
            <input
              type="text"
              {...register("name", { required: true })}
              className="p-2 border rounded-lg"
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">Nama harus diisi</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-[#4678f3] text-white py-1 px-5 rounded-lg"
          >
            Simpan
          </button>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default UpdateShippingComps;
