import React, { useState } from "react";
import { LuHome, LuLogOut } from "react-icons/lu";
import { GrDeliver } from "react-icons/gr";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { clearUser } from "../redux/userSlice";
import { logout } from "../services/authService";
import Swal from "sweetalert2";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state: RootState) => state.user.token);
  const [processLogout, setProcessLogout] = useState<boolean>(false);

  const isDashboardActive = pathname === "/admin";
  const isShippingCompsActive =
    pathname === "/admin/shipping-comps" ||
    pathname === "/admin/add-shipping-comps" ||
    pathname === "/admin/update-shipping-comps";

  const handleLogout = async () => {
    setProcessLogout(true);
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      await logout(token);

      setProcessLogout(false);
      dispatch(clearUser());

      navigate("/");

      Swal.fire({
        title: "Logged Out",
        text: "You have been logged out successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      setProcessLogout(false);

      Swal.fire({
        title: "Logout Failed",
        text: "There was a problem logging you out. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <aside className="w-1/4 bg-[#f2f2f2] shadow-md flex flex-col justify-between">
      <nav>
        <ul>
          <li className="my-1 px-2 border-b">
            <Link
              to="/admin"
              className={`flex gap-3 items-center text-lg py-2 px-4 ${
                isDashboardActive ? "text-[#2f80ed]" : "text-[#bababa]"
              }`}
            >
              <LuHome className="text-2xl" />
              <p>Dashboard</p>
            </Link>
          </li>
          <li className="my-1 px-2 border-b">
            <Link
              to="/admin/shipping-comps"
              className={`flex gap-3 items-center text-lg py-2 px-4 ${
                isShippingCompsActive ? "text-[#2f80ed]" : "text-[#bababa]"
              }`}
            >
              <GrDeliver className="text-2xl" />
              <p>Shipping Comps</p>
            </Link>
          </li>
        </ul>
      </nav>
      <div className="py-4 bg-[#4678f3] text-center flex justify-center">
        <button
          onClick={handleLogout}
          disabled={processLogout}
          className="text-white flex items-center gap-2 justify-center"
        >
          {processLogout ? (
            "Loading..."
          ) : (
            <>
              <LuLogOut /> <span>Log Out</span>
            </>
          )}
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
