import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { RootState, AppDispatch } from "../store";
import { addShippingComp } from "../redux/shippingCompsSlice";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AddShippingComps = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);
  const [process, setProcess] = useState<boolean>(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    setProcess(true);
    try {
      const resultAction = await dispatch(addShippingComp(data.name));

      if (addShippingComp.fulfilled.match(resultAction)) {
        Swal.fire({
          title: "Success!",
          text: "Shipping company added successfully.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/admin/shipping-comps");
        });
      } else {
        if (resultAction.payload) {
          Swal.fire({
            title: "Add Failed",
            text: resultAction.payload as string,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Add Failed",
            text: "An unexpected error occurred.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      }
    } catch (error) {
      console.error("Add failed", error);
      Swal.fire({
        title: "Add Failed",
        text: "An unexpected error occurred.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setProcess(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-xl h-full shadow-lg">
      <div className="flex gap-3 items-center">
        <h1 className="text-2xl font-bold text-left">Tambah Shipping Comps</h1>
      </div>
      <div className="mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block mb-1 text-[#808080]">Nama</label>
            <input
              type="text"
              {...register("name", {
                required: true,
              })}
              className="p-2 border rounded-lg"
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">Nama harus diisi</p>
            )}
          </div>
          <button
            type="submit"
            disabled={process}
            className=" bg-[#4678f3] text-white py-1 px-5 rounded-lg"
          >
            {process ? "Loading..." : "Simpan"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddShippingComps;
