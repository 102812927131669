import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";

const PublicLayout: React.FC = () => {
  return (
    <div className="h-[100vh] flex flex-col">
      <Header />
      <main className="flex-grow container mx-auto max-h-screen">
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
