import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchShippingComps = async (token: string) => {
  const response = await axios.get(`${API_URL}/finance/shippingComps`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const addShippingComp = async (token: string, name: string) => {
  const response = await axios.post(
    `${API_URL}/finance/shippingComps`,
    { name },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateShippingComp = async (
  token: string,
  id: string,
  name: string
) => {
  const response = await axios.put(
    `${API_URL}/finance/shippingComps/${id}`,
    { name },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const deleteShippingComp = async (token: string, id: string) => {
  const response = await axios.delete(
    `${API_URL}/finance/shippingComps/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};
