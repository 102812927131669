import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
  name: string;
  email: string;
  phoneNumber: string;
  profileImage: string;
  token: string;
}

const initialState: UserState = {
  name: "",
  email: "",
  phoneNumber: "",
  profileImage: "",
  token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{
        data: {
          user: {
            name: string;
            email: string;
            phone_number: string;
            profile_image: string;
          };
          data: { access_token: string };
        };
      }>
    ) => {
      const { data } = action.payload;
      state.name = data.user.name;
      state.email = data.user.email;
      state.phoneNumber = data.user.phone_number;
      state.profileImage = data.user.profile_image;
      state.token = data.data.access_token;
    },
    clearUser: () => initialState,
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
